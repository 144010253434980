import Vue from 'vue'
import Cookie from "js-cookie"
import axios from "axios";
import router from '../router'

const api = axios.create({
    baseURL: process.env.VUE_APP_CHECKPOINT_API,
    headers: {
        'Content-Type': 'application/json',
    }
});

api.interceptors.request.use(
    (config) => {
        console.log('requesting')
        config.headers.Authorization = `Bearer ${Cookie.get('access_token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    response => {
        return response
    },
    (error) => {
        console.log('error requesting', error)
        if(error.response.data.code == 'token.expired') {
            router.push('/login');
        }
        return Promise.reject(error);
    }
);
Vue.prototype.$api = api;
export default api;