import _ from "lodash"
import Http from 'Http'
const state = {
    profileTypes: [],
    profiles:[]
}
const getters = {

}
const actions = {
    async profile_types_init({ commit, dispatch }, unityId) {

        const [profileTypes] = await Promise.all([

            dispatch('get_profile_types', unityId)

        ]).catch(e => console.log(e))

        commit('SET_STATE', { state: 'profileTypes', data: profileTypes })

    },    
    async profiles_init({ commit, dispatch }, unityId) {

        const [profiles, profileTypes] = await Promise.all([

            dispatch('get_profiles', unityId),

            dispatch('get_profile_types', unityId)

        ]).catch(e => console.log(e))

        commit('SET_STATE', { state: 'profiles', data: profiles })

        commit('SET_STATE', { state: 'profileTypes', data: profileTypes })

    },    
    async add_edit_profile_init({ commit, dispatch }, unityId) {

        const [profileTypes] = await Promise.all([

            dispatch('get_profile_types', unityId)

        ]).catch(e => console.log(e))

        commit('SET_STATE', { state: 'profileTypes', data: profileTypes })

    },    
    async get_profiles({ }, unityId) {
        console.log('unityid', unityId)
        let data = []
        try {
            const response = await Http().get(`/profiles/${unityId}`)
            data = response.data.payload

        } catch (e) {
            console.log('err', e)
        }
        return data
    },
    async get_profile_by_id({ }, profileId) {

        try {
            const response = await Http().get(`/profiles/by_id/${profileId}`)
            console.log(response)
            return response.data.payload


        } catch (e) {
            console.log('err', e)
        }
    },
    async get_profile_types({ }, unityId) {
        console.log('unityid', unityId)
        let data = []
        try {
            const response = await Http().get(`/profile_types/${unityId}`)
            data = response.data.payload

        } catch (e) {
            console.log('err', e)
        }
        return data
    },
    async get_profile_types_by_id({ }, profileTypeId) {

        try {
            const response = await Http().get(`/profile_types/by_id/${profileTypeId}`)
            return response.data.payload


        } catch (e) {
            console.log('err', e)
        }
    },
    async save_profile_type({ }, profileType) {

        try {

            await Http().post('/profile_types', profileType)

            this.state.$app.toastSuccess('Tipo de perfil cadastrado com sucesso!')

            return true

        } catch ({ response }) {

            this.state.$app.toastError('profile_types/save_profile_type', response)

        }

        return false
    },
    async update_profile_type({ }, profileType) {

        try {

            await Http().patch('/profile_types', profileType)

            this.state.$app.toastSuccess('Tipo de perfil alterado com sucesso!')

            return true

        } catch ({response}) {

            this.state.$app.toastError('profile_types/update_profile_type', response)

        }

        return false
    },
    async save_profile({ }, profile) {

        try {

            await Http().post('/profiles', profile)

            this.state.$app.toastSuccess('Perfil cadastrado com sucesso!')

            return true

        } catch ({ response }) {

            this.state.$app.toastError('profiles/save_profile', response)

        }

        return false
    },
    async update_profile({ }, profile) {

        try {

            await Http().patch('/profiles', profile)

            this.state.$app.toastSuccess('Perfil alterado com sucesso!')

            return true

        } catch ({response}) {

            this.state.$app.toastError('profiles/update_profile', response)

        }

        return false
    },
}
const mutations = {
    ['SET_STATE']: (state, data) => {

        state[data.state] = data.data

    },
    ['PUSH_STATE']: (state, data) => {

        state[data.state].push(data.data)

    },
    ['SET_TEMPLATES']: (state, templates) => {

        state.templatesDefaultList = templates

    },
    ['INSERT_NEW_TEMPLATE']: (state, template) => {
        state.templatesList.push(template)
    }


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}