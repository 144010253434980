export default
[
    
    
    // {
    //     path: '/sistemicos/modelo/novo',
    //     name: 'sistemicos.new',        
    //     meta: {
    //         icon: 'file-code',
    //         title: 'Novo Modelo'           
    //     },
    //     component: () => import('@/views/templates/NewModel')        
    // },   
    // {
    //     path: '/sistemicos/modelo/:template_id/editar',
    //     name: 'sistemicos.model.edit',        
    //     meta: {
    //         icon: 'file-code',
    //         title: 'Editar Modelo'           
    //     },
    //     component: () => import('@/views/templates/EditModel')        
    // },   
    // {
    //     path: '/sistemicos/modelo',
    //     name: 'sistemicos.model',        
    //     meta: {
    //         icon: 'file-code',
    //         title: 'Lista de Modelos'           
    //     },
    //     component: () => import('@/views/templates/ListModel')        
    // },   
    {
        path: '/sistemicos/:tipo/novo',
        name: 'sistemicos.footer.new',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/templates/NewTemplate')        
    },
    {
        path: '/sistemicos/template',
        name: 'sistemicos.template.list',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/templates/ListTemplates')        
    },

]
