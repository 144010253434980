const rootPath = 'sistemicos/modelo'
export default
    [


        // {
        //     path: `${rootPath}/novo`,
        //     name: 'sistemicos.new',
        //     meta: {
        //         icon: 'file-code',
        //         title: 'Novo Modelo'
        //     },
        //     component: () => import('@/views/sistemic/models/Add')
        // },
        // {
        //     path: `${rootPath}/:template_id/editar`,
        //     name: 'sistemicos.model.edit',        
        //     meta: {
        //         icon: 'file-code',
        //         title: 'Editar Modelo'           
        //     },
        //     component: () => import('@/views/sistemic/models/EditModel')        
        // },   
        {
            path: `${rootPath}`,
            name: 'sistemicos.model',
            meta: {
                icon: 'file-code',
                title: 'Lista de Modelos'
            },
            component: () => import('@/views/sistemic/models/Index')
        },
        // {
        //     path: '/sistemicos/:tipo/novo',
        //     name: 'sistemicos.footer.new',        
        //     meta: {
        //         icon: 'file-code',
        //         title: 'Novo Modelo'           
        //     },
        //     component: () => import('@/views/sistemic/models/NewTemplate')        
        // },
        // {
        //     path: '/sistemicos/template',
        //     name: 'sistemicos.template.list',        
        //     meta: {
        //         icon: 'file-code',
        //         title: 'Novo Modelo'           
        //     },
        //     component: () => import('@/views/sistemic/models/ListTemplates')        
        // },

    ]
