import Http from 'Http';
const state = {
    data: {
         
    }
    
}
const getters = {
   
}
const actions = {
    async init({commit}){
        console.log('initi call')
        try{

            const result = await Http().get("/dashboard");
            console.log(result)
            commit ("SET_DATA",result.data.payload) 
        }catch(e){
            console.log('dash err', e)
        }        
    },
    socket_dashboard_update({commit}, data){
        console.log('data from socket', data)
        commit ("SET_DATA",data) 
    }
}
const mutations = {
    ["SET_DATA"]: (state, data) =>{
        state.data = data
    } 
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}