export default
[    
    //Profile Types
    {
        path: '/configuracoes/tipo_perfil',
        name: 'configurations.profile_type.list',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profile_types/Index')        
    },   
    {
        path: '/configuracoes/tipo_perfil/novo',
        name: 'configurations.profile_types.new',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profile_types/Add')        
    },   
    {
        path: '/configuracoes/tipo_perfil/:profileTypeId/editar',
        name: 'configurations.profile_types.profile_type_id.edit',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profile_types/Edit')        
    },   
    //Profiles
    {
        path: '/configuracoes/perfil',
        name: 'configurations.profile.list',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profiles/Index')        
    },   
    {
        path: '/configuracoes/perfil/novo',
        name: 'configurations.profiles.new',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profiles/Add')        
    },   
    {
        path: '/configuracoes/perfil/:profileId/editar',
        name: 'configurations.profiles.profile_id.edit',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/configurations/profiles/Edit')        
    },   

]
