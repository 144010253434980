import _ from "lodash"
import Http from 'Http'
import HttpImage from 'HttpImageServer'
const state = {
    templatesList: [],
    editTemplate: {},
    newTemplate: {},
    unityApplications: [],
    notificationTypes: [],
    templatesVariables: '',
    backgroundsList: [],
    applications: [],
    profiles: [],
    channels:[]
}
const getters = {

}
const actions = {
    // async init({ commit, dispatch, rootState }) {

    //     const [unityApplications, applications, templatesList, notificationTypes] = await Promise.all([

    //         dispatch('load_unity_applications'),

    //         dispatch('load_applications'),

    //         dispatch('load_templates'),

    //         dispatch('load_notification_types')

    //     ]).catch(e => console.log(e))

    //     const applicationsById = _.keyBy(applications, 'id')

    //     const notificationTypeById = _.keyBy(notificationTypes, 'id')

    //     let normalizedApplications = []

    //     _.forEach(unityApplications, (app) => {
    //         if (applicationsById[app.application_id]) {
    //             let temp = {
    //                 value: applicationsById[app.application_id].id,
    //                 description: applicationsById[app.application_id].name
    //             }
    //             normalizedApplications.push(temp)
    //         }
    //     })
    //     _.forEach(templatesList, (template) => {
    //         if (template.application_id) {
    //             template.application_name = applicationsById[template.application_id].name
    //         } else {
    //             template.application_name = 'Todas Aplicações'
    //         }
    //         if (template.notification_type_id) {
    //             template.notification_type_name = notificationTypeById[template.notification_type_id].description
    //         } else {
    //             template.application_name = 'Personalizado'
    //         }
    //     })


    //     commit('SET_STATE', { state: 'unityApplications', data: normalizedApplications })

    //     commit('SET_STATE', { state: 'notificationTypes', data: notificationTypes })

    //     commit('SET_STATE', { state: 'templatesList', data: templatesList })

    // },
    async list_init({ dispatch, commit }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [channels, unityApplications, applications, profiles, notificationTypes] = await Promise.all([

            dispatch('get_channels'),

            dispatch('load_unity_applications'),

            dispatch('load_applications'),

            dispatch('get_profiles', unityId),

            dispatch('load_notification_types')

        ]).catch(e => console.log(e))

        commit('SET_STATE', { state: 'channels', data: channels })

        commit('SET_STATE', { state: 'unityApplications', data: unityApplications })

        commit('SET_STATE', { state: 'notificationTypes', data: notificationTypes })

        commit('SET_STATE', { state: 'applications', data: applications })

        commit('SET_STATE', { state: 'profiles', data: profiles })

    },
    async edit_init({ commit, dispatch }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [unityApplications, applications, templatesList, notificationTypes, templatesVariables, backgroundsList, profiles] = await Promise.all([

            dispatch('load_unity_applications'),

            dispatch('load_applications'),

            dispatch('load_templates'),

            dispatch('load_notification_types'),

            dispatch('load_templates_variables'),

            dispatch('load_backgrounds'),

            dispatch('get_profiles', unityId),

        ]).catch(e => console.log(e))

        const applicationsById = _.keyBy(applications, 'id')

        const notificationTypeById = _.keyBy(notificationTypes, 'id')

        let normalizedApplications = []

        _.forEach(unityApplications, (app) => {
            if (applicationsById[app.application_id]) {
                let temp = {
                    value: applicationsById[app.application_id].id,
                    description: applicationsById[app.application_id].name
                }
                normalizedApplications.push(temp)
            }
        })
        _.forEach(templatesList, (template) => {
            if (template.application_id) {
                template.application_name = applicationsById[template.application_id].name
            } else {
                template.application_name = 'Todas Aplicações'
            }
            if (template.notification_type_id) {
                template.notification_type_name = notificationTypeById[template.notification_type_id].description
            } else {
                template.application_name = 'Personalizado'
            }
        })


        commit('SET_STATE', { state: 'unityApplications', data: normalizedApplications })

        commit('SET_STATE', { state: 'notificationTypes', data: notificationTypes })

        commit('SET_STATE', { state: 'templatesList', data: templatesList })

        commit('SET_STATE', { state: 'templatesVariables', data: templatesVariables })

        commit('SET_STATE', { state: 'backgroundsList', data: backgroundsList })

        commit('SET_STATE', { state: 'profiles', data: profiles })
    },
    async new_template_init({ commit, dispatch, rootState }, data) {

        const [unityApplications, applications, templatesList, notificationTypes, templatesVariables, backgroundsList] = await Promise.all([

            dispatch('load_unity_applications'),

            dispatch('load_applications'),

            dispatch('load_templates'),

            dispatch('load_notification_types'),

            dispatch('load_templates_variables'),

            dispatch('load_backgrounds')

        ]).catch(e => console.log(e))

        const applicationsById = _.keyBy(applications, 'id')

        const notificationTypeById = _.keyBy(notificationTypes, 'id')

        let normalizedApplications = []

        _.forEach(unityApplications, (app) => {
            if (applicationsById[app.application_id]) {
                let temp = {
                    value: applicationsById[app.application_id].id,
                    description: applicationsById[app.application_id].name
                }
                normalizedApplications.push(temp)
            }
        })
        _.forEach(templatesList, (template) => {
            if (template.application_id) {
                template.application_name = applicationsById[template.application_id].name
            } else {
                template.application_name = 'Todas Aplicações'
            }
            if (template.notification_type_id) {
                template.notification_type_name = notificationTypeById[template.notification_type_id].description
            } else {
                template.application_name = 'Personalizado'
            }
        })


        commit('SET_STATE', { state: 'unityApplications', data: normalizedApplications })

        commit('SET_STATE', { state: 'notificationTypes', data: notificationTypes })

        commit('SET_STATE', { state: 'templatesList', data: templatesList })

        commit('SET_STATE', { state: 'templatesVariables', data: templatesVariables })

        commit('SET_STATE', { state: 'backgroundsList', data: backgroundsList })
    },
    async get_channels() {

        let data = []

        try {

            const response = await Http().get('/channels')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async loadNotificationTypes() {

        const notificationTypes = await Http().get('/notification_types')

        return notificationTypes.data.payload
    },
    async update_template({ }, template) {



        if (template.type == 'content') {

            const response = await Http().patch('/communication_templates_custom', template)

        }
        if (template.type == 'header' || template.type == 'footer') {

            const response = await Http().patch('/templates_custom/update', template)

            this.dispatch('loaders/closeLoader')

            if (response.data.error) {

                this.dispatch('loaders/showModal', { msg: response.data.error })

            } else {

                this.dispatch('loaders/showModal', { msg: 'Template atualizado com sucesso!', icon: 'success' })

            }

        }


    },
    async updateTemplateDefault({ }, template) {

        const response = await Http().patch('/communication_templates_default/update', template)

        return response
    },
    async load_applications() {

        const applications = await Http().get('/applications')

        return applications.data.payload
    },
    async load_unity_applications() {

        const unity_id = this.state.auth.currentUser.unity_id

        const unity_applications = await Http().get(`/unity_applications/${unity_id}`)

        return unity_applications.data.payload
    },
    async load_templates({ }) {

        const unity_id = this.state.auth.currentUser.unity_id

        const templatesCustom = await Http().get(`/communication_templates_custom/${unity_id}?application_id=f504361b-f730-4748-ba63-c6dc4a2b94ee`)

        const templates = await Http().get(`/templates_custom/${unity_id}`)

        let data = [...templates.data.payload, ...templatesCustom.data.payload]

        return data

    },
    async load_notification_types() {

        const response = await Http().get('/notification_types')

        return response.data.payload
    },
    async load_templates_variables() {

        const unity_id = this.state.auth.currentUser.unity_id

        const response = await Http().get(`/templates_variables/${unity_id}`)

        return response.data.payload
    },
    async load_backgrounds() {

        const response = await Http().get('/backgrounds/read')

        return response.data.payload
    },
    async save_template({ commit }, template) {

        this.dispatch('loaders/showLoader')

        if (template.type == 'header' || template.type == 'footer') {
            const response = await Http().post('/templates_custom', template)
            if (response.data.msg == 'created') {

                commit('INSERT_NEW_TEMPLATE', response.data.payload)

                this.dispatch('loaders/closeLoader')

            } else {

                this.dispatch('loaders/closeLoader')

            }

        } else {

            const response = await Http().post('/communication_templates_custom/create', template)

            commit('INSERT_NEW_TEMPLATE', response.data.payload)

            this.dispatch('loaders/closeLoader')

        }
    },
    async upload_background({ commit }, background) {

        try {
            const response = await HttpImage().post(`/backgrounds/123/456`, background)

            if (response.data.status == 201) {

                const url = `http://localhost:3007/uploads/backgrounds/123/456/${response.data.fileName}`

                const response1 = await Http().post('/backgrounds', {
                    unity_id: null,
                    url,
                    active: '1'
                })

                if (response1.data.status == 201) {

                    commit('PUSH_STATE', { state: "backgroundsList", data: response1.data.payload })

                    return true

                } else {

                    return false

                }

            } else {

                return { error: response.data.msg }

            }
        } catch (e) {
            console.log('error', e)
        }
    },
    async get_profiles({ }, unityId) {

        let data = []

        try {
            const response = await Http().get(`/profiles/${unityId}`)
            data = response.data.payload

        } catch (e) {
            console.log('err', e)
        }
        return data
    },
    async get_templates({ commit }, params) {

        const unity_id = this.state.auth.currentUser.unity_id

        let queryString = ''

        const model = Object.keys(params)

        _.forEach(model, (item) => {

            queryString = `${queryString}${item}=${params[item]}&`

        })

        let data = []

        try {

            const response = await Http().get(`/communication_templates_custom/${unity_id}?${queryString}`)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/get_templates', response)
        }

        commit('SET_STATE', { state: "templatesList", data })

        return data

    },
    async update_model({}, model) {

        try {

            const response = await Http().patch('/communication_templates_custom', model)            

            return response

        } catch ({ response }) {

        }

    }
}
const mutations = {
    ['SET_STATE']: (state, data) => {

        state[data.state] = data.data

    },
    ['PUSH_STATE']: (state, data) => {

        state[data.state].push(data.data)

    },
    ['SET_TEMPLATES']: (state, templates) => {

        state.templatesDefaultList = templates

    },
    ['INSERT_NEW_TEMPLATE']: (state, template) => {
        state.templatesList.push(template)
    }


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}