export default
[    
    {
        path: '/sistemicos/modelos',
        name: 'sistemicos.new',        
        meta: {
            icon: 'file-code',
            title: 'Lista de Modelos'           
        },
        component: () => import('@/views/modelsCustom/Index')        
    },
    {
        path: '/sistemicos/modelos/novo',
        name: 'sistemicos.new',        
        meta: {
            icon: 'file-code',
            title: 'Novo Modelo'           
        },
        component: () => import('@/views/modelsCustom/Add')        
    },
    {
        path: '/sistemicos/modelos/:templateId/editar',
        name: 'sistemicos.new',        
        meta: {
            icon: 'file-code',
            title: 'Lista de Modelos'           
        },
        component: () => import('@/views/modelsCustom/Edit')        
    }
]
