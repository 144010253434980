import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './mixin'
import Card from '@/components/Card'
import VueSocketIO from 'vue-socket.io'
import Cookie from "js-cookie"

const token = Cookie.get('access_token')

Vue.config.productionTip = false

Vue.component('Card', Card)


// Vue.use(new VueSocketIO({ 
//    connection: 'http://localhost:3000/omni',
//   //connection: 'https://socket.apiserver.com.br/omni',
//   vuex: {
//       store,
//       actionPrefix: 'socket_',
//       mutationPrefix: 'SOCKET_'
//   },
//   options: {
//       path: '/socketserver',
//       withCredentials: true      
//   } 

// }))

new Vue({
  router,
  store,  
  render: h => h(App)
}).$mount('#app')
