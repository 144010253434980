import axios from 'axios'

export default () => {

    const api =  axios.create({

        baseURL: process.env.VUE_APP_API_IMAGE_SERVER,        
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    })   
    return api
}