import Vue from 'vue'
import { uuid } from 'vue-uuid'
const mixin = {
    computed: {
        /////////Channels
        channels() {
            return this.$store.state.global.channels
        },
        channelsById() {
            return _.keyBy(this.channels, 'id')
        },
        selectOptionsChannels() {
            return _.map(this.channels, (channel) => {
                return { text: channel.channel, value: channel.id };
            });
        },
        ///////Campaign Models
        campaignModels() {
            const campaignModels = _.filter(
                this.$store.state.global.models,
                (model) => {
                    return model.notification_type_id == null;
                }
            );
            return campaignModels;
        },
        campaignModelsById() {
            return _.keyBy(this.campaignModels, 'id')
        },
        selectOptionsCampaignModels() {
            return _.map(this.campaignModels, (model) => {
                return { text: model.description, value: model.id };
            });
        },
        /////////Files
        files() {
            return this.$store.state.global.files;
        },
        filesById() {
            return _.keyBy(this.files, "id");
        },
        selectOptionsFiles() {
            return _.map(this.files, (file) => {
                return { text: file.name, value: file.id };
            });
        },
    },    
    methods: {
        socket_join_room() {
            this.$socket.emit("join_room", {
                room: this.$store.state.auth.currentUser.unity_id                
            });
        },     
        socket_disconnect(){
            this.$socket.disconnect()
        },   
        socket_connect(){
            this.$socket.connect({auth:'123'})
        },
        socket_cmd(commit, data, namespace) {

            const normData = {
                commit,
                data,
                namespace,
                room: this.$store.state.auth.currentUser.unity_id
            }

            this.$socket.emit('cmd', normData)
        },
        get_uuid() {
            return uuid.v4()
        },
        toastError(storeAction, responseError) {
            const msg = `O servidor retornou status ${responseError.status}, com a seguinte mensagem ${responseError.statusText}`
            const title = `Opz! Algo deu errado em '${storeAction}'`
            this.$bvToast.toast(msg, {
                title,
                variant: 'danger'
            })
        },

        toastSuccess(title) {
            const msg = "Tudo Certo"
            this.$bvToast.toast(msg, {
                title,
                variant: 'success'
            })
        },
        check_tag(html, optionalTags, requiredTags) {
            optionalTags = optionalTags || []
            requiredTags = requiredTags || []
            let allTags = [...optionalTags, ...requiredTags]
            let required = [];
            let invalid = [];
            let get_template_tags = _.uniqBy(html.match(/{{\s*[\w\.]+\s*}}/g));
            if (get_template_tags) {
                get_template_tags = get_template_tags.map(function (x) {
                    return x.match(/[\w\.]+/)[0];
                });
            }
            _.forEach(get_template_tags, (tag) => {
                if (!allTags.includes(tag)) {
                    invalid.push(tag)
                }
            })
            _.forEach(requiredTags, (tag) => {
                if (!get_template_tags.includes(tag)) {
                    required.push(tag)
                }
            })
            return { invalid, required }
        },
        get_variables(html) {
            let get_template_tags = _.uniqBy(html.match(/{{\s*[\w\.]+\s*}}/g));
            if (get_template_tags) {
                get_template_tags = get_template_tags.map(function (x) {
                    return x.match(/[\w\.]+/)[0];
                });
            }
            return get_template_tags
        },
        getTag(tag) {
            return `{{${tag.replace(/ /g, "")}}}`;
        },
    }
}

Vue.mixin(mixin)