export default
    [
        {
            path: '/dashboard',
            name: 'dashboard',
            //need to check if user is authenticated? uncomment line below.
            // beforeRouteEnter: Auth, 
            meta: {
                icon: 'tachometer-alt',
                title: 'Dashboard'
            },
            component: () => import('@/views/dashboard/Index'),

        }
    ]