import _ from "lodash"
import Http from 'Http'
import HttpImage from 'HttpImageServer'
const state = {
    campaignFiles: [],
    applications: [],
    unityApplications: [],
    notificationTypes: [],
    profiles: [],
    templates: [],
    models: [],
    backgroundsList: [],
    channels: []
}
const getters = {

}
const actions = {

    //Models/////////////////////////////////////////////////////////////////////////////////////////

    async models_add_edit_init({ dispatch, commit }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [channels, applications, unityApplications, backgroundsList] = await Promise.all([

            dispatch('get_channels', unityId),

            dispatch('get_applications', unityId),

            dispatch('get_unity_applications', unityId),

            dispatch('get_backgrounds', unityId)

        ]).catch(error => this.state.$app.toastError('modelsCustom/list_init', error))

        commit('SET_STATE', { state: "channels", data: channels })

        commit('SET_STATE', { state: "applications", data: applications })

        commit('SET_STATE', { state: "unityApplications", data: unityApplications })

        commit('SET_STATE', { state: "backgroundsList", data: backgroundsList })

    },


    //Data/////////////////////////////////////////////////////////////////////////////////////////////

    async list_init({ dispatch, commit }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [campaignFiles] = await Promise.all([

            dispatch('get_campaign_files', unityId)

        ]).catch(error => this.state.$app.toastError('modelsCustom/list_init', error))

        commit('SET_STATE', { state: "campaignFiles", data: campaignFiles })

    },
    async add_edit_init({ dispatch, commit }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [channels, applications, unityApplications, profiles, notificationTypes] = await Promise.all([

            dispatch('get_channels'),

            dispatch('get_applications'),

            dispatch('get_unity_applications', unityId),


        ]).catch(error => this.state.$app.toastError('modelsCustom/list_init', error))

        commit('SET_STATE', { state: "channels", data: channels })

        commit('SET_STATE', { state: "applications", data: applications })

        commit('SET_STATE', { state: "unityApplications", data: unityApplications })

    },
    async get_campaign_files({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/campaign_files/${unityId}`)

            console.log(response)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/save_campaign_files', response)
        }

        return data
    },
    async get_channels() {

        let data = []

        try {

            const response = await Http().get('/channels')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_applications() {

        let data = []

        try {

            const response = await Http().get('/applications')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_unity_applications({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity_applications/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_notification_types({ }) {

        let data = []

        try {

            const response = await Http().get('/notification_types')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_profiles({ }, unityId) {

        let data = []

        try {
            const response = await Http().get(`/profiles/${unityId}`)
            data = response.data.payload

        } catch (e) {
            console.log('err', e)
        }
        return data
    },
    async get_backgrounds() {

        let data = []

        try {

            const response = await Http().get('/backgrounds/read')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
        
    },
    async get_templates({ commit }, params) {

        console.log(params)

        const unity_id = this.state.auth.currentUser.unity_id

        let queryString = ''

        if (params) {

            const model = Object.keys(params)

            _.forEach(model, (item) => {

                queryString = `${queryString}${item}=${params[item]}&`

            })
        }

        let data = []

        try {

            const response = await Http().get(`/templates_custom/${unity_id}?${queryString}`)

            console.log('response', response)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/get_templates', response)
        }

        commit('SET_STATE', { state: "templates", data })

        return data

    },
    async get_models({ commit }, params) {

        const unity_id = this.state.auth.currentUser.unity_id

        let queryString = ''

        const model = Object.keys(params)

        _.forEach(model, (item) => {

            queryString = `${queryString}${item}=${params[item]}&`

        })

        let data = []

        try {

            const response = await Http().get(`/communication_templates_custom/${unity_id}?${queryString}`)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/get_templates', response)
        }

        commit('SET_STATE', { state: "models", data })

        return data

    },
    async update_model({ }, model) {

        try {

            const response = await Http().patch('/communication_templates_custom/update', model)

            return response

        } catch ({ response }) {

        }

    },
    async save_campaign_files({ }, model) {
        try {

            const response = await Http().post(`/campaign_files/create`, model)

            console.log(response)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/save_campaign_files', response)
        }
    }
}
const mutations = {
    ['SET_STATE']: (state, data) => {

        state[data.state] = data.data

    },
    ['PUSH_STATE']: (state, data) => {

        state[data.state].push(data.data)

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}