import Http from 'Http'
import HttpImage from 'HttpImageServer'
import { uuid } from 'vue-uuid'
const state = {
    channels: [],
    applications: [],
    unityApplications: [],
    backgroundsList: [],
    notificationTypes: [],
    templates: [],
    models: [],
    files: []
}
const getters = {

}
const actions = {
    async app_init({ dispatch, commit }) {

        const unityId = this.state.auth.currentUser.unity_id

        const [channels,
            applications,
            unityApplications,
            backgroundsList,
            notificationTypes,
            templates,
            models,
            files] = await Promise.all([

                dispatch('get_channels', unityId),

                dispatch('get_applications', unityId),

                dispatch('get_unity_applications', unityId),

                dispatch('get_backgrounds', unityId),

                dispatch('get_notification_types'),

                dispatch('get_templates', unityId),

                dispatch('get_models', unityId),

                dispatch('get_campaign_files', unityId)

            ]).catch(error => this.state.$app.toastError('modelsCustom/list_init', error))

        commit('SET_STATE', { state: "channels", data: channels })

        commit('SET_STATE', { state: "applications", data: applications })

        commit('SET_STATE', { state: "unityApplications", data: unityApplications })

        commit('SET_STATE', { state: "backgroundsList", data: backgroundsList })

        commit('SET_STATE', { state: "notificationTypes", data: notificationTypes })

        commit('SET_STATE', { state: "templates", data: templates })

        commit('SET_STATE', { state: "models", data: models })

        commit('SET_STATE', { state: "files", data: files })

    },
    socket_att_dashboard({}, value){
        const result = Http().get('/qqlugar')
    },
    socket_global({ commit }, value) {

        commit(value.commit, value.data)

    },
    async get_campaign_files({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/campaign_files/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/save_campaign_files', response)
        }

        return data
    },
    async get_channels() {

        let data = []

        try {

            const response = await Http().get('/channels')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_applications() {

        let data = []

        try {

            const response = await Http().get('/applications')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_unity_applications({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity_applications/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_notification_types({ }) {

        let data = []

        try {

            const response = await Http().get('/notification_types')

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_profiles({ }, unityId) {

        let data = []

        try {
            const response = await Http().get(`/profiles/${unityId}`)
            data = response.data.payload

        } catch (e) {
            console.log('err', e)
        }
        return data
    },
    async get_backgrounds({}, unityId) {

        let data = []

        try {

            const response = await Http().get(`/backgrounds/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            throw response

        }

        return data

    },
    async get_models({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/communication_templates_custom/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/get_templates', response)
        }

        return data

    },
    async get_templates({ }, unityId) {



        // const unity_id = this.state.auth.currentUser.unity_id

        // let queryString = ''

        // if (params) {

        //     const model = Object.keys(params)

        //     _.forEach(model, (item) => {

        //         queryString = `${queryString}${item}=${params[item]}&`

        //     })
        // }

        let data = []

        try {

            const response = await Http().get(`/templates_custom/${unityId}`)

            data = response.data.payload

        } catch ({ response }) {

            this.state.$app.toastError('templates/get_templates', response)
        }

        return data

    },
    async save_template({ commit }, template) {

        try {

            const response = await Http().post('/templates_custom', template)

            const data = response.data.payload

            commit('PUSH_STATE', { state: 'templates', data })

            return true

        } catch ({ response }) {

            return false

        }
    },
    async save_model({ commit }, model) {

        try {

            const response = await Http().post('/communication_templates_custom', model)

            if (response.data.status !== 500) {

                const data = response.data.payload

                const active = _.filter(state.models, (m) => {
                    return m.application_id == model.application_id &&
                        m.notification_type_id == model.notification_type_id &&
                        m.unity_id == this.state.auth.currentUser.unity_id &&
                        m.channel_id == model.channel_id &&
                        m.active == '1'
                })

                if (active.length > 0) {

                    active[0].active = '0'

                }

                commit('PUSH_STATE', { state: 'models', data })

                this.state.$app.socket_cmd('PUSH_STATE', { state: 'models', data }, 'global')

                this.state.$app.socket_cmd('QQ coisa', { state: 'modelsqualquer', data:{abc:'cede'} }, 'teste_rubao')

                this.state.$app.socket_cmd('QQ coisa', { state: 'modelsqualquer', data:{abc:'cede'} }, 'att_boleto')



                return true
            }

        } catch ({ response }) {

            console.log('save_template', response)

            return false

        }
    },
    async socket_att_agora(){
        console.log('AGORA FOI CHAMADO')
    },
    async socket_att_boleto(){
        const unityId = this.state.auth.currentUser.unity_id
        const response = await Http().get(`/templates_custom/${unityId}`)
        console.log('chamada pelo socket do get', response)
    },
    async update_model({ commit }, model) {

        try {

            const response = await Http().patch('/communication_templates_custom', model)

            if (response.data.status !== 500) {

                commit('UPDATE', { state: 'models', data: model })

                return true

            } else {

                return false

            }

        } catch ({ response }) {

            this.state.$app.toastError('global/update_model', response)

        }

    },
    async upload_background({ commit, rootState }, background) {

        const unityId = rootState.auth.currentUser.unity_id

        try {

            const response = await HttpImage().post(`/backgrounds/${unityId}`, background)

            if (response.data.status == 201) {

                const url = `${process.env.VUE_APP_API_IMAGE_SERVER}/uploads/backgrounds/${unityId}/${response.data.fileName}`

                const response1 = await Http().post('/backgrounds', {
                    id: uuid.v4(),
                    unity_id: unityId,
                    url,
                    active: '1'
                })

                if (response1.data.status == 201) {

                    commit('PUSH_STATE', { state: "backgroundsList", data: response1.data.payload })

                    return true

                } else {

                    return false

                }

            } else {

                return { error: response.data.msg }

            }
        } catch (error) {

            return { error }
        }
    },
}
const mutations = {
    ['SET_STATE']: (state, data) => {
        state[data.state] = data.data
    },
    ['PUSH_STATE']: (state, data) => {
        const index = _.findIndex(state[data.state], (item) => {
            return item.id == data.data.id
        })
        if (index == -1) {

            state[data.state].push(data.data)
        }
    },
    ['UPDATE']: (state, data) => {
        const index = _.findIndex(state[data.state], (item) => {
            return item.id == data.data.id
        })
        state[data.state].splice(index, 1, data.data)

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}