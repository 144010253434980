export default
    [
        // {
        //     path: '/campanhas/base-de-dados',
        //     name: 'sistemicos.new',        
        //     meta: {
        //         icon: 'file-code',
        //         title: 'Base de Dados'           
        //     },
        //     component: () => import('@/views/campaigns/data/Index')        
        // },
        {
            path: '/campanhas/base-de-dados/novo',
            name: 'sistemicos.new',
            meta: {
                icon: 'file-code',
                title: 'Base de Dados'
            },
            component: () => import('@/views/campaigns/data/Add')
        },
        {
            path: '/campanhas/base-de-dados/:campaignFileId/editar',
            name: 'sistemicos.new',
            meta: {
                icon: 'file-code',
                title: 'Base de Dados'
            },
            component: () => import('@/views/campaigns/data/Edit')
        },
        {
            path: '/campanhas/modelos',
            name: 'campaign.models',
            meta: {
                icon: 'file-code',
                title: 'Base de Dados'
            },
            component: () => import('@/views/campaigns/models/Index')
        },
        {
            path: '/campanhas/modelos/novo',
            name: 'campaign.models.new',
            meta: {
                icon: 'file-code',
                title: 'Base de Dados'
            },
            component: () => import('@/views/campaigns/models/Add')
        },
        {
            path: `/campanhas/modelos/:template_id/editar`,
            name: 'sistemicos.model.edit',
            meta: {
                icon: 'file-code',
                title: 'Editar Modelo'
            },
            component: () => import('@/views/campaigns/models/Edit')
        },
        {
            path: `/campanhas`,
            name: 'campaigns.list',
            meta: {
                icon: 'file-code',
                title: 'Editar Modelo'
            },
            component: () => import('@/views/campaigns/list/Index')
        },
        {
            path: `/campanhas/novo`,
            name: 'campaigns.list',
            meta: {
                icon: 'file-code',
                title: 'Editar Modelo'
            },
            component: () => import('@/views/campaigns/list/Add')
        },
    ]
