const state = {
    modelsList:[],
   
};
const getters = {}
const actions = {}
const mutations = {
    ['SET']: (state, data) => {
        state[data.state] = data.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}