export default
[    
    {
        path: '/admin/novo-legado',
        name: 'admin.newlegacy',        
        meta: {
            icon: 'file-code',
            title: 'Modelo Legado'           
        },
        component: () => import('@/views/admin/LoadLegacy')        
    }    
]
